import React from "react"
import Head from '../components/Head';
import '../styles/_pages.scss';

import Header from "../components/Headers/Header";
import Footer from "../components/Footers/Footer";
import PageHeader from "../components/PageHeaders/PageHeader";
//import CommingSoon from "../components/Service/ComingSoon";
import Elems from "../templates/elems";
import { PAGE_TITLE } from '../content/almanac/constans';
import { data } from '../content/almanac/almanac.json';

const PageTitle = PAGE_TITLE[0];


const IndexPage = () => {

  //const takeData = () => {dataTXT.fetch}
  return <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main id="almanac-page" className="main">
      <PageHeader pageTitle={PageTitle}/>
      <Elems data={data}/>
      {/* <CommingSoon/> */}
    </main>

    <Footer />

  </>
}

export default IndexPage
